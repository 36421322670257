<template>
  <q-btn-dropdown
    ref="qBtnDropdown"
    class="mi-btn mi-btn-dropdown"
    :class="{ 'q-btn--icon' : iconType }"
    :auto-close="autoClose"
    :color="color"
    :dense="dense"
    :disable="disable"
    :disable-dropdown="disableDropdown"
    :disable-main-btn="disableMainBtn"
    :dropdown-icon="iconRight"
    :no-icon-animation="noIconAnimation"
    :fab="fab"
    :flat="flat"
    :label="label"
    :loading="loading"
    :outline="outline"
    :persistent="persistent"
    :split="split"
    :icon="icon"
    no-caps
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-btn-dropdown>
</template>

<script>
  export default {
    name: 'MiBtnDropdown',
    props: {
      autoClose: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      noIconAnimation: {
        type: Boolean,
        required: false,
        default: false
      },
      disableDropdown: {
        type: Boolean,
        required: false,
        default: false
      },
      disableMainBtn: {
        type: Boolean,
        required: false,
        default: false
      },
      iconSize: {
        type: String,
        required: false,
        default: ''
      },
      iconRightSize: {
        type: String,
        required: false,
        default: ''
      },
      fab: {
        type: Boolean,
        required: false,
        default: false
      },
      flat: {
        type: Boolean,
        required: false,
        default: false
      },
      icon: {
        type: String,
        required: false,
        default: undefined
      },
      iconRight: {
        type: String,
        required: false,
        default: ''
      },
      label: {
        type: [String, Number],
        required: false,
        default: undefined
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      outline: {
        type: Boolean,
        required: false,
        default: false
      },
      persistent: {
        type: Boolean,
        required: false,
        default: false
      },
      split: {
        type: Boolean,
        required: false,
        default: false
      },
      iconType: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      hide() {
        this.$refs.qBtnDropdown?.hide?.()
      },
      show() {
        this.$refs.qBtnDropdown?.show?.()
      },
      toggle() {
        this.$refs.qBtnDropdown?.toggle?.()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .mi-btn.mi-btn-dropdown ::v-deep(.q-btn__content) {
    align-items: baseline;
    min-width: 40px;

    .q-icon {
      font-size: .75em;
      align-self: center;
      font-weight: 300;
    }
  }
</style>
